.navbar.navbar-static-top {
  padding: 15px 0;

  .navbar-brand {
    padding: 0 15px;

    img {
      max-width:150px;
    }
  }
}

.container {
  .nav-tabs {
    li.active > a {
      background: #fff;
    }
  }

  .first-tab-row {
    .panel {
      padding-top: 15px;
      border-top: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;

      .form-group {
        @media (min-width: 768px) {
          label.control-label { text-align: left; }
        }
      }
    }
  }

  .table th {
    border-top: none;
  }
}

.btn-default {
  color : $btn-primary-color;
  background-color: $btn-primary-bg;
  border: $btn-primary-border;
}

.holidays-container {
  .remove-holiday {
    margin-top: 3rem;
  }
}


.articletype-collapse-container {
  margin-bottom: 3px;

  .btn-articletype-collapse {
    position: relative;
    text-align: left;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

    padding-left: 25px;

    i.glyphicon {
      position: absolute;
      top: 3px;
      left: 5px;

      &:before { font-size: 10px; }

      &.glyphicon-triangle-right { display: none; }
      &.glyphicon-triangle-bottom { display: block; }
    }

    &.collapsed {
      border-radius: 3px;

      i.glyphicon.glyphicon-triangle-right { display: block; }
      i.glyphicon.glyphicon-triangle-bottom { display: none; }
    }
  }

  .collapse, .collapsing {
    padding: 0 15px;
    border-left: 1px solid #EEE;
    border-right: 1px solid #EEE;
    border-bottom: 1px solid #EEE;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;

    .row {
      padding: 5px 0;

      &:first-child { padding-top: 10px; }
      &.row-even { background: #F5F5F5; }
    }
  }
}

.mb20 { margin-bottom: 20px; }

.panel-heading {
  position: relative;

  .panel-heading-right {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px 15px;
  }
}

#page-wrapper {
  position: relative;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);

  -webkit-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
  transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;

  #app, #off-canvas-menu {
    min-height: 100vh;
  }

  &.off-canvas-menu-open {
    -webkit-transform: translate3d(-20rem, 0, 0);
    transform: translate3d(-20rem, 0, 0);

    #off-canvas-menu {
      display: block;
    }
  }

  #off-canvas-menu {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: -20rem;
    width: 20rem;
    background: #2b303b;
    -webkit-box-shadow: inset 5px 0 15px 0 rgba(0, 0, 0, .25);
    box-shadow: inset 5px 0 15px 0 rgba(0, 0, 0, .25);

    .off-canvas-nav {
      width: 100%;
      list-style: none;
      margin: 0;
      padding: 0;

      -webkit-transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;
      transition: all cubic-bezier(0.215, 0.61, 0.355, 1) 0.3s;

      li a {
        display: block;
        padding: 10px 15px;
        color: #99b8bc;
        border-bottom: 1px solid #304e52;

        &:hover {
          color: #FFF;
          text-decoration: none;
        }
      }
    }
  }
}

// table made out of divs
.pseudo-table {
  margin: 0 15px;

  .pseudo-table-header,
  .pseudo-table-body-row {
    padding: 8px 5px;
    & > div { padding: 0; }
  }

  .pseudo-table-header {
    font-weight: bold;
    border-bottom: 2px solid #DDD;
  }
  .pseudo-table-body {
    .pseudo-table-body-row {
      border-top: 1px solid #DDD;

      &:first-child { border-top: none; }

      &.form-buttons-row {
        padding: 15px 0;
      }

      .article-name {
        font-weight: normal;
        margin: 0;
      }

      .pseudo-table-label {
        display: inline-block;
        font-weight: bold;
        width: 175px;
        vertical-align: top;
      }

      .weight-kg-field,
      .weight-g-field,
      .pieces-field {
        display: inline-block;

        .control-label {
          display: inline;
          font-weight: normal;
        }
      }

      .weight-kg-field {
        width: 75px;
        margin-right: 10px;
      }

      .weight-g-field {
        width: 75px;
      }

      .pieces-field {
        width: 165px;
      }
    }
  }
}

.form-control {
  padding : 6px;
}

.collect-go {
  margin : 30px 0px;
}