// PDF styling

.print-pdf {

  margin-left: 30px;

  table {
    font-size:12px;

    tr > td {
      padding: 5px;
      line-height: 1;
    }
  }
}